<template>
    <div class="modal modal--crypto pfixed top__zero" id="crypto">
        <div class="modal__inner">
            <button type="button" class="btn modal__btn-close" @click="togglePopup('crypto')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                </svg>
            </button>
            <h2 class="modal__title">{{ $props.crypto.ticker }}</h2>
            <div class="modal__content">

                <div class="wallet_raw">
                    <span class="wallet">{{ $props.crypto.address }}</span>
                    <span class="copy_text" @click="copyText($props.crypto.address)">
                        <svg width="30" height="30" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.3315 19.0439V32.1393C28.3315 34.6903 26.2728 36.9011 23.528 36.9011H9.80355C7.23022 36.9011 5 34.8604 5 32.1393V13.4317C5 10.8807 7.05867 8.6698 9.80355 8.6698H17.8667C19.0675 8.6698 20.2685 9.18 21.2978 10.0303L26.9592 15.6426C27.8168 16.663 28.3315 17.8534 28.3315 19.0439Z" fill="#958BA3"></path>
                        <path d="M32.1049 30.0984C31.4186 30.0984 30.7324 29.5882 30.7324 28.7379V16.4929C30.7324 15.9827 30.5609 15.4725 30.0463 14.9623L21.8116 6.79903C21.4684 6.4589 20.7823 6.11877 20.2676 6.11877H13.2338C12.5476 6.28883 11.8613 5.60857 11.8613 4.92828C11.8613 4.24802 12.5476 3.56775 13.2338 3.56775H20.4391C21.6399 3.56775 22.8409 4.07795 23.6986 4.92828L31.9333 13.0915C32.7911 13.9419 33.3058 15.1324 33.3058 16.3228V28.7379C33.4773 29.4182 32.7911 30.0984 32.1049 30.0984Z" fill="#958BA3"></path>
                        </svg>
                    </span>
                </div>

                <div class="wallet_raw" style="margin-top: 10px">
                    <span class="amount">{{ $props.crypto.amount }}</span>
                    <span class="copy_text" @click="copyText($props.crypto.amount)">
                        <svg width="30" height="30" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.3315 19.0439V32.1393C28.3315 34.6903 26.2728 36.9011 23.528 36.9011H9.80355C7.23022 36.9011 5 34.8604 5 32.1393V13.4317C5 10.8807 7.05867 8.6698 9.80355 8.6698H17.8667C19.0675 8.6698 20.2685 9.18 21.2978 10.0303L26.9592 15.6426C27.8168 16.663 28.3315 17.8534 28.3315 19.0439Z" fill="#958BA3"></path>
                        <path d="M32.1049 30.0984C31.4186 30.0984 30.7324 29.5882 30.7324 28.7379V16.4929C30.7324 15.9827 30.5609 15.4725 30.0463 14.9623L21.8116 6.79903C21.4684 6.4589 20.7823 6.11877 20.2676 6.11877H13.2338C12.5476 6.28883 11.8613 5.60857 11.8613 4.92828C11.8613 4.24802 12.5476 3.56775 13.2338 3.56775H20.4391C21.6399 3.56775 22.8409 4.07795 23.6986 4.92828L31.9333 13.0915C32.7911 13.9419 33.3058 15.1324 33.3058 16.3228V28.7379C33.4773 29.4182 32.7911 30.0984 32.1049 30.0984Z" fill="#958BA3"></path>
                        </svg>
                    </span>
                </div>

                <div class="wallet_qr">
                    <qrcode-vue 
                        v-if="$props.crypto.address"
                        :value="$props.crypto.address" 
                        size="150" 
                        level="H" 
                        render-as="svg" />
                </div>

                <div class="crypto__desc">
                    <p>{{ $sprintf($store.state.translation.wallet.deposit.send_only, $props.crypto.ticker) }}</p>
                    <p>{{ $store.state.translation.wallet.deposit.min_or_lost }}</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    export default {
        components: {
            QrcodeVue,
        },
        props: ['crypto'],
        data() {
            return {
                
            }
        },
        methods: {
            copyText(text) {
                navigator.clipboard.writeText(text)
                this.$toast.success(this.$store.state.translation.wallet.deposit.copied)
            }
        }
    }
</script>
